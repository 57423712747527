import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const SimpleHeroWrap = styled.div`
  ul, li {
    list-style: none;
  }

  .breadcrumbs-menu {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const Wrappy = styled.div`
  display: flex;
  
  ul, li {
    list-style: none;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding: 50px;
`;

const RightSide = styled.div`
  width: 40%;
`;

const SimpleHero = props => {

  return (
    
      <>
          {!props.img ?
          <SimpleHeroWrap className="breadcrumbs-title white-bg pt-90 pb-80">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="breadcrumbs-menu">
                    <h2>{props.headline}</h2>
                    <ul className="clearfix">
                      <li><Link to="/">Home</Link><span>|</span> </li>
                      {props.secondaryURL && props.pageName && <li><Link to={props.secondaryURL}>{props.secondaryName}</Link><span>|</span> </li>}
                      {props.secondaryURL && !props.pageName && <li><Link to={props.secondaryURL}>{props.secondaryName}</Link></li>}
                      <li>{props.pageName}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SimpleHeroWrap>
          : 
          <Wrappy className="pb-80">
            <LeftSide>
              <div className="breadcrumbs-menu">
                <h2>{props.headline}</h2>
                <ul className="clearfix">
                  <li><Link to="/">Home</Link><span>|</span> </li>
                  {props.secondaryURL && props.pageName && <li><Link to={props.secondaryURL}>{props.secondaryName}</Link><span>|</span> </li>}
                  {props.secondaryURL && !props.pageName && <li><Link to={props.secondaryURL}>{props.secondaryName}</Link></li>}
                  <li>{props.pageName}</li>
                </ul>
                {props.previous && (
                  <Link to={`/${props.pagePath}/${props.previous.slug}/`}>
                    &#8592; Previous
                    {props.next && <>{" "}|{" "}</>}
                  </Link>
                )}
                {props.next && (
                  <Link to={`/${props.pagePath}/${props.next.slug}/`}>Next &#8594;</Link>
                )}
              </div>
            </LeftSide>
            <RightSide>
              <Img fluid={props.img} />
            </RightSide>
          </Wrappy>
          }
          
        
    </>
  )
}

export default SimpleHero
