import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  /* width: 100%; */
`

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 3;
`

const PageIndicator = styled.span`
  color: gray;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 1em 1.5em;
  z-index: -1;
  opacity: 0.7;
`

class Pagination extends React.Component {
  render() {
    let { numPages, currentPage, slug, pathPrefixName } = this.props.context
    slug = this.props.slug ? this.props.slug : slug
    const urlBase = this.props.urlBase ? this.props.urlBase : ''

    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const isNotPaginated = isFirst & isLast

    const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1
    const nextPageNum = currentPage + 1

    const pathPrefix = typeof slug === 'string' ? `${slug}` : ''
    const prevPageLink = isFirst ? null : `${pathPrefix}/${prevPageNum}/`
    const nextPageLink = isLast ? null : `${pathPrefix}/${nextPageNum}/`

    return (
      <div className="blog-area style2 white-bg pt-40 mb-60 clearfix">
        <div className="container">
          <div className="row">
            <Wrapper className="col-xs-12 col-sm-8 col-md-9 col-text-center mobi-mb-50">
              {!isFirst && (
                <PreviousLink
                  className="btn theme-btn mt-20"
                  to={`${urlBase}/${prevPageLink}`}
                >
                  &#8592; Prev Page
                </PreviousLink>
              )}
              {!isNotPaginated && (
                <PageIndicator>
                  {currentPage}/{numPages}
                </PageIndicator>
              )}
              {!isLast && (
                <NextLink
                  className="btn theme-btn mt-20"
                  to={`${urlBase}/${nextPageLink}`}
                >
                  Next Page &#8594;
                </NextLink>
              )}
            </Wrapper>
          </div>
        </div>
      </div>
    )
  }
}

export default Pagination
