import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SimpleHero from '../components/SimpleHero'
import ProjectCardList from '../components/ProjectCardList'
import ProjectCard from '../components/ProjectCard'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Projects = ({ data, pageContext }) => {
  const projects = data.allContentfulProject.edges
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1
  const seo = {
    pageTitle: 'Projects',
    shareImage: {
      ogimg: {
        src: projects[0].node.coverImage.ogimg.src,
        width: projects[0].node.coverImage.ogimg.width,
        height: projects[0].node.coverImage.ogimg.height,
      },
    },
    metaDescription: {
      metaDescription: "KPS's Projects",
    },
    metaKeywords: {
      metaKeywords: 'KPC Projects',
    },
  }

  return (
    <Layout>
      {seo && <SEO pagePath={pageContext.slug} postNode={seo} pageSEO />}
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Projects: Page ${currentPage}`}</title>
        </Helmet>
      )}
      <Container>
        <SimpleHero headline="Work We've Done & Love" pageName="Projects" />

        <ProjectCardList>
          {projects.map(({ node: project }) => (
            <ProjectCard key={project.id} {...project} />
          ))}
        </ProjectCardList>
      </Container>
      <Pagination context={pageContext} urlBase="" slug="projects" />
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulProject(
      sort: { fields: [completedDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          name
          slug
          content {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
          completedDate(formatString: "MMMM DD, YYYY")
          category {
            title
            slug
          }
          coverImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
            ogimg: resize(width: 1800) {
              src
              width
              height
            }
          }
          images {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          elementsOfDesign
          services
        }
      }
    }
  }
`

export default Projects
