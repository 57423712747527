import React from 'react'
import styled from 'styled-components'
import Equalizer from "react-equalizer";

const CardList = props => {
  return (
    <div className="portfolio-area style-1 column-2 white-bg pb-120 clearfix">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="portfolio-grid fitRows-grid">
              <Equalizer>
                {props.children}
              </Equalizer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardList
