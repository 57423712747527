import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import theme from '../styles/theme'

const ProjectTitle = styled.span`
  font-size: 18px;
  color: ${props => props.theme.color.black};
`;

const Card = ({ slug, coverImage, name, createdAt, content, category, ...props }) => {
  return (
    <div className="grid-item photography design percent-50 col-xs-12 col-sm-12 col-md-6">
      <div className="single-portfolio">
        <Link className="venobox overlay dark-4" data-gall="gall-img" to={`/projects/${slug}/`}>
          <Img fluid={coverImage.fluid} />
        </Link>
        <div className="project-title text-center">
          <Link to={`/projects/${slug}/`}><ProjectTitle>{name}</ProjectTitle></Link>
          <p>{category.title}</p>
        </div>
      </div>
    </div>
  )
}

export default Card
